<template>
  <div>
    <div>
      <div class="page-head-css">
        <div style="padding-top: 10px">
          <a-row>
            <a-col :span="8">
              <div style="float: left; margin-left: 10px">
                <span>仪表类型: </span>
                <a-select
                  style="width: 150px"
                  allowClear
                  v-model="selectType"
                  placeholder="请选择设备类型"
                  @change="selectTypeChange"
                >
                  <a-select-option value="PUMP"> 抽水泵 </a-select-option>
                  <a-select-option value="VALVE"> 水阀 </a-select-option>
                  <a-select-option value="GENERAL"> 普通开关 </a-select-option>
                </a-select>
              </div>
            </a-col>
            <a-col :span="8">
              <div style="display: flex; justify-content: center; flex: 1">
                <a-badge status="success" />
                启动
                <a-badge status="error" style="margin-left: 10px" />
                关闭
                <a-badge status="default" style="margin-left: 10px" />
                离线
              </div>
            </a-col>
            <a-col :span="8">
              <div style="float: right; margin-right: 10px">
                <span>农场基地: </span>
                <a-select
                  v-model="selectFarmArea"
                  placeholder="请选择农场基地"
                  allowClear
                  style="width: 150px"
                  @change="selectFarmAreaChange"
                >
                  <a-select-option v-for="item in farmAreaList" :key="item.id">
                    {{ item.fullName }}
                  </a-select-option>
                </a-select>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div style="margin: 0px 20px">
        <div style="margin-bottom: 8px"></div>
        <a-row :gutter="20">
          <a-col
            :span="spanNum"
            v-for="item in relayControlData"
            :key="item.id"
          >
            <a-card
              hoverable
              class="card-css"
              :style="{
                borderTop:
                  item.state === 'OPEN'
                    ? '4px solid #52c41a'
                    : item.state === 'CLOSE'
                    ? '4px solid #D9001B'
                    : '4px solid #909399',
              }"
              :key="item.id"
            >
              <div style="display: flex; justify-content: space-between">
                <div style="margin-left: 10px; margin-top: 5px">
                  <img
                    src="../../img/relay/消防水泵.png"
                    style="width: 40px; height: 40px"
                    v-if="item.type === 'PUMP'"
                  />
                  <img
                    src="../../img/relay/水.png"
                    style="width: 40px; height: 40px"
                    v-else-if="item.type === 'VALVE'"
                  />
                  <img
                    src="../../img/relay/智能插座.png"
                    style="width: 40px; height: 40px"
                    v-else
                  />
                  <span style="font-size: 14px; color: black">
                    {{ item.name }}
                  </span>
                </div>
                <div style="margin-right: 10px; margin-top: 5px">
                  <span style="color: #5b5b5b; font-size: 10px"
                    >最后操作时间:{{ item.time | dateFormatFull }}</span
                  >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>编辑</span>
                    </template>
                    <a-icon
                      type="edit"
                      theme="twoTone"
                      style="margin-left: 5px"
                      @click="editRelayControl(item)"
                    />
                  </a-tooltip>
                </div>
              </div>

              <div>
                <div style="padding: 40px 25px 0px 25px">
                  <a-row>
                    <a-col :span="24">
                      <div style="text-align: center">
                        <div style="height: 30px">
                          <span
                            :style="{
                              fontSize: '20px',
                              fontWeight: '600',
                              color:
                                item.state === 'OPEN'
                                  ? '#52c41a'
                                  : item.state === 'CLOSE'
                                  ? '#D9001B'
                                  : '#909399',
                            }"
                            >{{ item | stateFilter }}</span
                          >
                        </div>
                        <div>
                          <span style="color: #373737; font-size: 12px"
                            >当前状态</span
                          >
                        </div>
                      </div>
                    </a-col>
                    <!--<a-col :span="12">
                      <div style="text-align: center">
                        <div style="height: 30px">
                          <span
                            :style="{
                              fontSize: '23px',
                              fontWeight: '600',
                              color:
                                item.state === 'OPEN'
                                  ? '#52c41a'
                                  : item.state === 'CLOSE'
                                  ? '#D9001B'
                                  : '#909399',
                            }"
                            >{{
                              item.state === "OPEN"
                                ? openMinute(item.time)
                                : "-"
                            }}</span
                          >
                          <span
                            :style="{
                              fontSize: '10px',
                              fontWeight: '400',
                              marginLeft: '3px',
                              color:
                                item.state === 'OPEN'
                                  ? '#52c41a'
                                  : item.state === 'CLOSE'
                                  ? '#D9001B'
                                  : '#909399',
                            }"
                            >分钟</span
                          >
                        </div>
                        <div>
                          <span style="color: #373737; font-size: 12px"
                            >开启时间</span
                          >
                        </div>
                      </div>
                    </a-col>-->
                  </a-row>
                </div>
              </div>
              <div>
                <div style="padding: 50px 15px 0px 15px">
                  <a-row>
                    <a-col :span="12">
                      <div style="margin-left: 40px">
                        <div style="margin-top: 12px">
                          <span style="font-size: 13px">开关类型:</span>
                        </div>
                        <div style="margin-top: 12px">
                          <span style="font-size: 13px">继电器通道:</span>
                        </div>
                        <div style="margin-top: 12px">
                          <span style="font-size: 13px">农场基地:</span>
                        </div>
                        <div
                          style="margin-top: 12px"
                          v-auth="'relayControlData-operation'"
                        >
                          <span style="font-size: 13px">操作:</span>
                        </div>
                      </div>
                    </a-col>
                    <a-col :span="12">
                      <div>
                        <div style="margin-top: 12px; margin-left: 20px">
                          <span style="color: #373737; font-size: 13px">{{
                            item.type | typeFiltr
                          }}</span>
                        </div>
                        <div style="margin-top: 12px; margin-left: 20px">
                          <span style="color: #373737; font-size: 13px">{{
                            item.information
                          }}</span>
                        </div>
                        <div
                          style="
                            margin-top: 12px;
                            margin-left: 20px;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                        >
                          <span style="color: #373737; font-size: 13px">{{
                            item.farmAreaName
                          }}</span>
                        </div>
                        <div
                          style="margin-top: 12px;"
                          v-auth="'relayControlData-operation'"
                        >
                          <span>
                            <el-button
                              size="mini"
                              style="
                                background-color: #169bd5;
                                color: #ffffff;
                                border: 0px;
                              "
                              @click="openRelay(item)"
                              >开启</el-button
                            >
                            <el-button
                              size="mini"
                              style="
                                background-color: #ec808d;
                                color: #ffffff;
                                border: 0px;
                              "
                              @click="closeRelay(item)"
                              >关闭</el-button
                            >
                          </span>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>
              <div style="position: absolute; right: 0; bottom: 0"></div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>
    <template>
      <div>
        <a-drawer
          title="配置设备"
          width="630"
          placement="right"
          :closable="false"
          :visible="editRelayControlVisible"
          @close="editRelayControlVisible = false"
        >
          <div>
            <a-form-model
              :model="relayControlFormValue"
              ref="relayControlFormValueRefForm"
              :rules="relayControlFormRules"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 13 }"
            >
              <a-form-model-item label="设备名称" prop="name">
                <a-input
                  placeholder="请输入设备名称"
                  allowClear
                  v-model="relayControlFormValue.name"
                />
              </a-form-model-item>
              <a-form-model-item label="农场基地" prop="farmAreaId">
                <a-select
                  v-model="relayControlFormValue.farmAreaId"
                  placeholder="请选择农场基地"
                  allowClear
                  style="width: 100%"
                >
                  <a-select-option v-for="item in farmAreaList" :key="item.id">
                    {{ item.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备类型" prop="type">
                <a-select
                  style="width: 100%"
                  allowClear
                  v-model="relayControlFormValue.type"
                  placeholder="请选择设备类型"
                  @change="typeChange"
                >
                  <a-select-option value="PUMP"> 抽水泵 </a-select-option>
                  <a-select-option value="VALVE"> 水阀 </a-select-option>
                  <a-select-option value="GENERAL"> 普通开关 </a-select-option>
                </a-select>
              </a-form-model-item>
              <div v-if="relayControlFormValue.type === 'PUMP'">
              <a-form-model-item label="关联液位传感器" prop="measurePointId">
                <a-select
                    style="width: 100%"
                    allowClear
                    v-model="relayControlFormValue.measurePointId"
                    placeholder="请选择关联的液位传感器"
                >
                  <a-select-option :value="item.id" v-for="item in liquidLevelMeasurePointList" :key="item.id"> {{item.customMeterAddress}}{{item.customMeterName}}#{{item.informationId}} </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="最低液位(cm)" prop="liquidLevelNum">
                <a-input
                    placeholder="请输入最低液位 请输入整数"
                    allowClear
                    v-model="relayControlFormValue.liquidLevelNum"
                />
              </a-form-model-item>
              <a-form-model-item label="到达最低液位是否自动关闭" prop="autoClose">
                <a-select
                    style="width: 100%"
                    allowClear
                    v-model="relayControlFormValue.autoClose"
                    placeholder="请选择到达最低液位是否自动关闭"
                >
                  <a-select-option :value="true"> 是 </a-select-option>
                  <a-select-option :value="false"> 否 </a-select-option>
                </a-select>
              </a-form-model-item>
              </div>

              <a-form-model-item
                label="抽水泵"
                prop="parent"
                v-if="relayControlFormValue.type === 'VALVE'"
              >
                <a-select
                  style="width: 100%"
                  allowClear
                  v-model="relayControlFormValue.parent"
                  placeholder="请选择连接的抽水机!"
                >
                  <a-select-option
                    v-for="item in relayControlData"
                    :key="item.id"
                    v-if="item.type === 'PUMP'"
                  >
                    {{ item.name }}#{{ item.information }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <div
                v-if="relayControlFormValue.type === 'VALVE'"
                style="margin-left: 50px"
              >
                <span> 请选择该水阀与哪一个抽水泵连接 </span>
                <br />
                <span> 如果抽水机未打开 水阀将不能单独打开 </span>
              </div>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editRelayControlVisible = false"
            >
              关闭
            </a-button>
            <a-button type="primary" @click="toEditRelayControl">
              确定
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
import countTo from "vue-count-to";

export default {
  components: {
    countTo,
  },
  data() {
    return {
      editRelayControlVisible: false,
      relayControlFormValue: {
        id: "",
        name: "",
        farmAreaId: "",
        parent: [],
        type: [],
          measurePointId: [],
          autoClose: true,
          liquidLevelNum: "",
      },
      relayControlFormRules: {
        name: [{ required: true, message: "请输入设备名称!", trigger: "blur" }],
        farmAreaId: [
          { required: true, message: "请选择农场基地!", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择设备类型!", trigger: "blur" }],
        parent: [
          { required: false, message: "请选择连接的抽水机!", trigger: "blur" },
        ],
      },
      farmAreaList: [],
      selectFarmArea: [],
      selectType: [],
      timer: "timer",
      relayControlData: [],
        liquidLevelMeasurePointList: [],
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50, //屏幕高度
    };
  },
  computed: {
    spanNum() {
      let spans = ["24", "12", "8", "6", "4", "3"];
      let number = Math.floor(this.screenWidth / 420) - 1;
      if (number < 5) {
        return spans[number];
      } else if (number == 5) {
        return spans[3];
      } else if (number == 6 || number == 7) {
        return spans[4];
      } else if (number > 7) {
        return spans[5];
      } else {
        return spans[0];
      }
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
    this.timer = setInterval(this.getRelayControlData, 10000);
  },
  filters: {
    typeFiltr: function (value) {
      if (!value) return "";
      if (value === "PUMP") {
        return "抽水泵";
      } else if (value === "VALVE") {
        return "水阀";
      } else if (value === "GENERAL") {
        return "普通开关";
      } else {
        return "";
      }
    },
    stateFilter: function (value) {
      if (!value) return "";
      if (value.state === "CLOSE") {
        return "关闭";
      } else if (value.state === "OPEN" && value.type === "PUMP") {
        return "抽水中";
      } else if (value.state === "OPEN") {
        return "打开";
      } else if (value.state === "OFFLINE") {
        return "离线";
      } else {
        return "";
      }
    },
  },

  created() {
    this.getRelayControlData();
    this.getFarmAreaData();
    this.getLiquidLevelMeasurePointList();
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    typeChange() {
      if (this.relayControlFormValue.type === "VALVE") {
        this.relayControlFormRules.parent[0].required = true;
      } else {
        this.relayControlFormRules.parent[0].required = false;
      }
    },
    toEditRelayControl() {
        console.log(this.relayControlFormValue);
      this.$refs.relayControlFormValueRefForm.validate((valid) => {
        if (valid) {
          this.http.relay
            .updateRelayControlPoint(this.relayControlFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.getRelayControlData();
              } else {
                this.$message.warning(response.data.msg);
              }
              this.editRelayControlVisible = false;
            });
        }
      });
    },
    editRelayControl(obj) {
      this.relayControlFormValue = {
        id: obj.id,
        name: obj.name,
        farmAreaId: obj.farmAreaId,
        parent: obj.parent,
        type: obj.type,
          measurePointId:obj.measurePointId,
          autoClose: obj.autoClose,
          liquidLevelNum: obj.liquidLevelNum,
      };
      this.typeChange();
      this.editRelayControlVisible = true;
    },
    selectFarmAreaChange() {
      this.getRelayControlData();
    },
    getFarmAreaData() {
      this.http.farmArea.getFarmAreaList().then((response) => {
        if (response.data.status == 200) {
          this.farmAreaList = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    selectTypeChange() {
      this.getRelayControlData();
    },
    openMinute(date) {
      let newDate = this.moment(new Date());
      let oldDate = this.moment(date);
      let number = newDate.diff(oldDate, "minute");
      return number;
    },

      async toOpenRelay(obj){
          const { data: res } = await this.http.relay.operationRelayControlPoint({
              id: obj.id,
              state: "1",
          });
          if (res.status !== 200) {
              return this.$message.error(res.msg);
          }
          this.getRelayControlData();
          this.$notification.open({
              message: "操作提示",
              description: "打开" + obj.name + "成功",
              icon: <a-icon type="check-circle" style="color: #52c41a" />,
      });
      },
    async openRelay(obj) {
        if(obj.type === 'PUMP'){
            //查看当前液位传感器水位
            const { data: res } = await this.http.relay.judgeRelayControlPoint({
                id: obj.id,
            });
            if (res.status == 200) {
                this.toOpenRelay(obj);
            } else{
                this.$confirm({
                    title: '当前水位过低,确定开启吗?',
                    content: '',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        this.toOpenRelay(obj);
                    },
                    onCancel() {
                    },
                });
            }
        }else {
            this.toOpenRelay(obj);
        }
    },
    async closeRelay(obj) {
      const { data: res } = await this.http.relay.operationRelayControlPoint({
        id: obj.id,
        state: "0",
      });
      if (res.status !== 200) {
        return this.$message.error(res.msg);
      }
      this.getRelayControlData();
      this.$notification.open({
        message: "操作提示",
        description: "关闭" + obj.name + "成功",
        icon: <a-icon type="check-circle" style="color: #52c41a" />,
      });
    },

    async getRelayControlData() {
      const { data: res } = await this.http.relay.getRelayControlPointList({
        type: this.selectType,
        farmAreaId: this.selectFarmArea,
      });
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.relayControlData = res.data;
    },
      async getLiquidLevelMeasurePointList() {
      const { data: res } = await this.http.device.getLiquidLevelMeasurePointList();
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.liquidLevelMeasurePointList = res.data;
    },
  },
};
</script>

<style scoped>
.card-css >>> .ant-card-body {
  padding: 0px;
}

.card-css {
  width: 100%;
  height: 22rem;
  margin: 10px 0px;
}

/*.crad-detail {
    text-align: center;
    padding: 0 0px 20px 0px;
  }

  .card-css >>> .ant-card-body {
    padding: initial;
    width: 100%;
    height: 180px;
  }

  .real-data-card {
    margin: 20px;
    border: 1px solid #EBEEF5;
    color: #303133;
    background-color: #FFF;
    box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
    border-radius: 2px;
  }*/
</style>
